import { inject, Injectable } from '@angular/core';
import { Observable, combineLatest, filter, map } from 'rxjs';

import { UserPermission } from '@dartsales/common/core/enums/user-permission';
import { UserRole } from '@dartsales/common/core/enums/user-role';

import { CurrentUserService } from './current-user.service';
import { ProjectLayoutService } from './project-layout.service';

/**
 * The project creation date is necessary to verify the user permission of DuplicateProject.
 * Projects created after this date cannot be duplicated.
 * More information: https://saritasa.atlassian.net/browse/DART-2222.
 */
const PROJECT_CREATED_AT = new Date('2025/01/15');

/** Project permissions service. */
@Injectable()
export class ProjectPermissionsService {

  private readonly userService = inject(CurrentUserService);

  private readonly projectLayoutService = inject(ProjectLayoutService);

  /**
   * Whether user has permission in current project.
   * @param permission Permission.
   */
  public hasPermissionInCurrentProject(permission: UserPermission): Observable<boolean> {
    if (permission === UserPermission.DuplicateProject) {
      return this.hasPermissionToDuplicateProject();
    }
    return this.projectLayoutService.hasPermissionInCurrentOrganization([permission]);
  }

  private hasPermissionToDuplicateProject(): Observable<boolean> {
    return combineLatest([
      this.userService.currentUser$,
      this.projectLayoutService.userRoles$,
      this.projectLayoutService.project$,
      this.projectLayoutService.hasPermissionInCurrentOrganization([UserPermission.DuplicateProject]).pipe(
        filter(Boolean),
      ),
    ]).pipe(
      map(([currentUser, roles, project]) => {
        if (currentUser?.isGlobalAdmin) {
          return true;
        }

        const allowedRoles = UserPermission.getAllowedRoles(UserPermission.DuplicateProject);
        const userAllowedRoles = roles.filter(role => allowedRoles.includes(role));
        const hasOnlySalespersonRole = userAllowedRoles.length === 1 && userAllowedRoles.includes(UserRole.SalesPerson);

        if (project.createdAt && hasOnlySalespersonRole) {
          return project.createdAt > PROJECT_CREATED_AT;
        }

        return true;
      }),
    );
  }
}

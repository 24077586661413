import { Injectable, inject } from '@angular/core';

import { DetailedFinalEstimate, FinalEstimate, FinalEstimateAlternate, FinalEstimateEditData, FinalEstimateSummaries } from '@dartsales/common/core/models/estimate/final-estimate/final-estimate';
import { EntityValidationErrors } from '@dartsales/common/core/models/errors/app-error';
import { FinalEstimateStatus } from '@dartsales/common/core/models/estimate/final-estimate/final-estimate-status';
import { DomainBasePricingCalculator } from '@dartsales/common/core/models/calculators/domain/domain-base-pricing-calculator';
import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';
import { FinalEstimateLocksState } from '@dartsales/common/core/models/estimate/final-estimate/final-estimate-locks-state';

import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from '../../mappers';
import { DetailedFinalEstimateDto, EditFinalEstimateOrderDto, FinalEstimateAlternateDto, FinalEstimateDto, FinalEstimateEditDto, FinalEstimatePreviewDto } from '../../dto/estimate/final-estimate/final-estimate.dto';
import { MappedValidationErrorDto } from '../../dto/validation-error.dto';
import { extractErrorMessage } from '../../errors/extract-error-message';
import { DateMapper } from '../../date-mapper';
import { ModulePropertiesMapper } from '../modules/module-properties.mapper';
import { ModulesListMapper } from '../modules-list.mapper';
import { BasePricingMapper } from '../../pricing/base-pricing.mapper';
import { EstimateLocksStateMapper } from '../estimate-locks-state.mapper';

import { FinalEstimateServiceTotalsMapper } from './modules/service-totals/service-totals.mapper';

/** Final estimate mapper. */
@Injectable({
  providedIn: 'root',
})
export class FinalEstimateMapper implements
  IMapperFromDto<FinalEstimateDto, FinalEstimate>,
  IMapperToDto<FinalEstimateEditDto, FinalEstimateEditData>,
  IValidationErrorMapper<FinalEstimateEditDto, FinalEstimateEditData> {

  private readonly propertiesMapper = inject(ModulePropertiesMapper);

  private readonly modulesListMapper = inject(ModulesListMapper);

  private readonly basePricingMapper = inject(BasePricingMapper);

  private readonly dateMapper = inject(DateMapper);

  private readonly finalEstimateServiceTotalsModuleMapper = inject(FinalEstimateServiceTotalsMapper);

  private readonly locksStateMapper = inject(EstimateLocksStateMapper);

  /** @inheritdoc */
  public fromDto(dto: FinalEstimateDto): FinalEstimate {
    return new FinalEstimate({
      ...this.basePricingMapper.fromDto(dto),
      id: dto.id,
      name: dto.name,
      order: dto.order,
      description: dto.description ?? '',
      status: dto.isAwarded ? FinalEstimateStatus.Awarded : FinalEstimateStatus.Pending,
      includedAlternateEstimates: dto.alternates.map(alternate => this.fromAlternateDto(alternate)),
      createdAt: this.dateMapper.fromDto(dto.createdAt),
      updatedAt: this.dateMapper.fromDto(dto.updatedAt),
      bulkUpdateStatus: dto.baseEstimateBulkUpdateStatus,
      locksStateInfo: new FinalEstimateLocksState({
        alternates: dto.sellPriceLocks.alternates.map(alternate => this.locksStateMapper.fromDto(alternate)),
        baseEstimate: this.locksStateMapper.fromDto(dto.sellPriceLocks.baseEstimate),
      }),
    });
  }

  /**
   * Map detailed final estimate from DTO.
   * @param dto DTO.
   */
  public fromDetailedDto(dto: DetailedFinalEstimateDto): DetailedFinalEstimate {
    return new DetailedFinalEstimate({
      ...this.fromDto(dto),
      baseEstimateId: dto.baseEstimateId,
      modules: this.modulesListMapper.mapFromFinalModuleListDto(dto),
      modulesTotals: this.propertiesMapper.fromDto(dto.totalsModule.properties),
      serviceTotals: this.finalEstimateServiceTotalsModuleMapper.fromDto(dto.serviceTotals),
      baseEstimateTdcAmount: dto.baseEstimateTdcAmount,
    });
  }

  /**
   * Map final estimate summaries from DTO.
   * @param dto DTO.
   */
  public mapSummariesFromDto(dto: FinalEstimatePreviewDto): FinalEstimateSummaries {
    const basePricing = DomainBasePricingCalculator.calculatePricing({
      originalPricing: {
        directCost: dto.directCost,
        contingency: { amount: dto.contingency.amount },
        margin: { markup: { amount: dto.markup.amount } },
        escalation: { amount: dto.escalation?.amount },
      },
      contingencyUnits: AmountCalcUnits.Amount,
      marginType: MarginType.Markup,
      marginUnits: AmountCalcUnits.Amount,
      escalationUnits: AmountCalcUnits.Amount,
    });

    return {
      ...basePricing,
      sellPrice: dto.sellPrice,
      modules: this.modulesListMapper.mapFromFinalModuleListDto(dto),
      modulesTotals: this.propertiesMapper.fromDto(dto.totalsModule.properties),
      serviceTotals: this.finalEstimateServiceTotalsModuleMapper.fromDto(dto.serviceTotals),
      locksStateInfo: new FinalEstimateLocksState({
        alternates: dto.alternatesSellPriceLocks.map(alternate => this.locksStateMapper.fromDto(alternate)),
        baseEstimate: this.locksStateMapper.fromDto(dto.baseEstimateSellPriceLocks),
      }),
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: MappedValidationErrorDto<FinalEstimateEditDto> | null | undefined,
  ): EntityValidationErrors<FinalEstimateEditData> {
    return {
      name: extractErrorMessage(errorDto?.name),
      description: extractErrorMessage(errorDto?.description),
    };
  }

  /** @inheritdoc */
  public toDto(data: FinalEstimateEditData): FinalEstimateEditDto {
    return {
      name: data.name,
      description: data.description,
      alternateIds: data.alternateEstimateIds,
      isAwarded: data.status === FinalEstimateStatus.Awarded,
    };
  }

  /**
   * Map final estimate to order DTO.
   * @param estimate Final estimate.
   * @param order Order number.
   */
  public toOrderDto(estimate: FinalEstimate, order: number): EditFinalEstimateOrderDto {
    return {
      id: estimate.id,
      order,
    };
  }

  private fromAlternateDto(dto: FinalEstimateAlternateDto): FinalEstimateAlternate {
    return {
      id: dto.id,
      name: dto.name,
      description: dto.description ?? '',
    };
  }
}

import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';
import { SellPriceLockState } from '@dartsales/common/core/enums/sell-price-lock-state';

import { EstimateLocksState } from '../estimate-locks-state';

/** Final estimate locks state. */
export class FinalEstimateLocksState {

  /** Alternate estimates. */
  public readonly alternates: readonly EstimateLocksState[];

  /** Base estimate. */
  public readonly baseEstimate: EstimateLocksState;

  /** Final estimate sell price lock state. */
  public readonly sellPriceLockState: SellPriceLockState;

  /** Projects totals sell price lock state. */
  public readonly projectTotalsSellPriceLockState: SellPriceLockState;

  /** Service totals sell price lock state. */
  public readonly servicesTotalsSellPriceLockState: SellPriceLockState;

  public constructor(data: FinalEstimateLocksStateInitArgs) {
    this.alternates = data.alternates;
    this.baseEstimate = data.baseEstimate;
    this.sellPriceLockState = this.getLockState();
    this.projectTotalsSellPriceLockState = this.getProjectTotalsSellPriceLockState();
    this.servicesTotalsSellPriceLockState = this.getServicesTotalsSellPriceLockState();
  }

  private getLockState(): SellPriceLockState {
    const baseEstimateLockState = this.baseEstimate.sellPriceLockState;
    if (baseEstimateLockState === SellPriceLockState.Locked &&
      this.alternates.every(alternate => alternate.sellPriceLockState === SellPriceLockState.Locked)) {
      return SellPriceLockState.Locked;
    }

    if (baseEstimateLockState === SellPriceLockState.NotAvailable &&
      this.alternates.every(alternate => alternate.sellPriceLockState === SellPriceLockState.NotAvailable)) {
      return SellPriceLockState.NotAvailable;
    }

    return SellPriceLockState.Unlocked;
  }

  private getProjectTotalsSellPriceLockState(): SellPriceLockState {
    const allModules = this.alternates
      .map(alternate => alternate.modules)
      .concat(this.baseEstimate.modules);

    return EstimateLocksState.getLockState(allModules, []);
  }

  private getServicesTotalsSellPriceLockState(): SellPriceLockState {
    const terms = this.alternates
      .flatMap(alternate => alternate.terms)
      .concat(this.baseEstimate.terms);

    return EstimateLocksState.getLockState([], terms);
  }
}

type FinalEstimateLocksStateInitArgs = StrictOmit<FinalEstimateLocksState, 'sellPriceLockState'
  | 'projectTotalsSellPriceLockState'
  | 'servicesTotalsSellPriceLockState'>;

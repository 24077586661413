import { InjectionToken, Provider, inject } from '@angular/core';
import { Observable, combineLatest, map, shareReplay, switchMap } from 'rxjs';

import { ProjectLayoutService } from '@dartsales/common/core/services/project-layout.service';
import { ProjectNavigationService } from '@dartsales/common/core/services/project-navigation.service';
import { ProjectAlternateEstimatesService } from '@dartsales/common/core/services/project-alternate-estimates.service';
import { ProjectFinalEstimatesService } from '@dartsales/common/core/services/project-final-estimates.service';
import { ProjectChangeOrdersService } from '@dartsales/common/core/services/project-change-orders.service';
import { UserPermission } from '@dartsales/common/core/enums/user-permission';
import { PermissionsService } from '@dartsales/common/core/services/permissions.service';
import { ProjectStatus } from '@dartsales/common/core/enums/project-status';
import { CustomProjectPageSearchService } from '@dartsales/common/core/services/custom-project-page-search.service';
import { ProjectPermissionsService } from '@dartsales/common/core/services/project-permissions.service';

type IsReadonlyProjectToken = Observable<boolean>;

/** Whether current project is readonly. */
export const IS_READONLY_PROJECT_TOKEN = new InjectionToken<IsReadonlyProjectToken>(
  'Whether current project is readonly',
);

/** Whether current permission is readonly. */
export const IS_READONLY_PROJECT_PERMISSION_TOKEN = new InjectionToken<IsReadonlyProjectToken>(
  'Whether current permission is readonly',
);

/** Is readonly project token factory. */
export function isReadonlyProjectTokenFactory(): IsReadonlyProjectToken {
  return combineLatest([
    isReadonlyProjectPermissionTokenFactory(),
    isReadonlyProjectStatusTokenFactory([ProjectStatus.Accepted, ProjectStatus.Rejected, ProjectStatus.Canceled, ProjectStatus.OnHold]),
  ])
    .pipe(
      map(([noPermission, readonlyProjectStatus]) => noPermission || readonlyProjectStatus),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
}

/** Is read-only project permission token factory. */
export function isReadonlyProjectPermissionTokenFactory(): IsReadonlyProjectToken {
  const permissionsService = inject(PermissionsService);
  const projectLayoutService = inject(ProjectLayoutService);
  return projectLayoutService.project$.pipe(
    switchMap(project => {
      const hasPermission$ = projectLayoutService.hasPermissionInCurrentOrganization(
        [UserPermission.EditProject],
      );
      const isTeamMember$ = permissionsService.checkIsTeamMember(project.projectInfo.otherTeamMembers);

      return combineLatest([hasPermission$, isTeamMember$]);
    }),
    map(([hasPermission, isTeamMember]) => !(hasPermission || isTeamMember)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );
}

/**
 * Is read-only project status token factory.
 * @param readonlyProjectStatuses Read-only project statuses.
 */
export function isReadonlyProjectStatusTokenFactory(readonlyProjectStatuses: readonly ProjectStatus[]): IsReadonlyProjectToken {
  return inject(ProjectLayoutService).project$
    .pipe(
      map(project => readonlyProjectStatuses.includes(project.status)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
}

/** Provide readonly project permission. */
export const provideReadonlyProjectPermission = (): Provider => ({
  provide: IS_READONLY_PROJECT_TOKEN,
  useFactory: isReadonlyProjectPermissionTokenFactory,
});

/** Project layout providers. */
export const PROJECT_LAYOUT_PROVIDERS: Provider[] = [
  ProjectLayoutService,
  ProjectPermissionsService,
  ProjectNavigationService,
  ProjectAlternateEstimatesService,
  ProjectFinalEstimatesService,
  ProjectChangeOrdersService,
  CustomProjectPageSearchService,
  {
    provide: IS_READONLY_PROJECT_TOKEN,
    useFactory: isReadonlyProjectTokenFactory,
  },
];
